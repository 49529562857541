import cn from "classnames"
import styles from "./Button.module.scss"

export const Button: React.FC<{
  text: string
  type?: string
  htmlType?: "button" | "submit"
  className?: string
  value?: "login" | "register"
  id?: string
  onClick?: () => void
}> = ({
  text,
  type = "default",
  htmlType = "button",
  className,
  value,
  id,
  onClick,
}) => {
  return (
    <button
      className={cn([
        className,
        styles.button,
        { [styles.button_primary]: type === "primary" },
        { [styles.button_light]: type === "light" },
      ])}
      type={htmlType}
      onClick={onClick}
      value={value}
      id={id}
    >
      {text}
    </button>
  )
}
