import { useEffect, useState } from "react"
import { MemeCard } from "../../components/common/MemeCard/MemeCard"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"
import { firebase } from "../../lib/firebase"
import styles from "./Feed.module.scss"
import { Meme } from "../../types/meme"
import axios from "axios"
import MemeCardSkeleton from "../../components/common/MemeCardSkeleton/MemeCardSkeleton"
import SiteNavigationBar from "../../components/common/SiteNavigationBar/SiteNavigationBar"

export const FeedPage: React.FC = () => {
  const [memes, setMemes] = useState<Meme[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchMemes = async () => {
      const token = await firebase.getToken()
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      try {
        const response = await axios.get(
          `${apiUrl}/images?code=czrxZH70pKk9j6dkup6o5fFb7_h5IDcoZtg5mpnpyIwbAzFus7SVuQ==`,
          { headers },
        )
        setMemes(response.data)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }

    fetchMemes()
  }, [apiUrl])

  return (
    <BaseLayout>
      <SiteNavigationBar text="Feed" />
      <div className={styles.feed}>
        {isLoading && <MemeCardSkeleton cards={3} />}
        {memes.map(meme => (
          <div className={styles.card} key={meme.uid}>
            <MemeCard meme={meme} />
          </div>
        ))}
      </div>
    </BaseLayout>
  )
}
