import StripeOnboarding from "../../components/common/StripeOnboarding/StripeOnboarding"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"
import { useTitle } from "../../hooks/useTitle"

export const SettingsPage: React.FC = () => {
  useTitle("Settings")
  return (
    <BaseLayout>
      <StripeOnboarding />
    </BaseLayout>
  )
}
