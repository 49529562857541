import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import axios from "axios";

export const useStripeConnect = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        try {
          const response = await axios.post(`${apiUrl}/AccountSession?code=ZEjNLbtbzATyDGMo49oTYNI7UlW41Ne13JyoHoA-BJcDAzFuZxLbGA==`, {
            account: connectedAccountId,
          }, {
            headers: {
              "Content-Type": "application/json",
            },
          });
  
          const { client_secret: clientSecret } = response.data;
          return clientSecret;
        } catch (error) {
          if (error.response) {
            // TODO: handle errors
            const { error: responseError } = error.response.data;
            throw new Error(`An error occurred: ${responseError}`);
          } else {
            // TODO: Handle other errors such as network issues
            throw new Error(`An error occurred: ${error.message}`);
          }
        }
      };
  
      fetchClientSecret().then((clientSecret) => {
        setStripeConnectInstance(
          loadConnectAndInitialize({
            publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            fetchClientSecret,
            appearance: {
              overlays: "dialog",
              variables: {
                colorPrimary: "#ec41e6",
              },
            },
          })
        );
      }).catch(error => {
        console.error(error);
      });
    }
  }, [connectedAccountId, apiUrl]);

  return stripeConnectInstance;
};
