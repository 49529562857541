import { useState, createContext, PropsWithChildren, useEffect } from 'react';
import { firebase } from '../lib/firebase';
import { User } from '../types/user';

type UserContextProps = {
  user: User | null,
  logout: () => void,
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  logout: () => null,
})

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)

  const logout = firebase.logout

  const authStatusChangeHandler = (user: User) => {
    if (user) {
      const { email, uid } = user
      setUser({ email, uid })
    } else {
      setUser(null)
    }
  }

  useEffect(() => {
    firebase.onAuthStateChange(authStatusChangeHandler)
  }, [])

  return (
    <UserContext.Provider value={{ user, logout }}>
      { children }
    </UserContext.Provider>
  )
}