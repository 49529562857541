import { SignupForm } from '../../components/forms/Signup/Signup'
import styles from "./Signup.module.scss"

export const SignupPage: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.greetMessage}>
          Welcome to MeMA!
        </p>
        <SignupForm />
      </div>
    </div>
  )
}