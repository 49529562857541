import React from "react"
import styles from "./MemeCardSkeleton.module.scss"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const MemeCardSkeleton: React.FC<{
  cards: number
}> = ({ cards }) => {
  const skeletonElements: JSX.Element[] = Array(cards)
    .fill(0)
    .map((_, index) => (
      <div key={index} className={styles.skeleton}>
        <div className={styles.avatar}>
          <Skeleton circle height={35} width={35} />
          <div className={styles.header}>
            <Skeleton height={22} />
          </div>
        </div>
        <div className={styles.image}>
          <Skeleton height={400} width={670} />
        </div>
      </div>
    ))

  return <>{skeletonElements}</>
}

export default MemeCardSkeleton
