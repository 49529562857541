import { useNavigate } from "react-router-dom"
import { firebase } from "../../../lib/firebase"
import { Button } from "../../common/Button/Button"
import { Input } from "../../common/Input/Input"
import styles from "./Login.module.scss"

export const LoginForm: React.FC = () => {
  const navigate = useNavigate()

  const submitHandler = async (event: any) => {
    event.preventDefault()

    const { email: emailInput, password: passwordInput } = event.target.elements
    const email = emailInput.value
    const password = passwordInput.value
    const { id } = event.nativeEvent.submitter
    const userName = "userName"

    try {
      if (id === "loginSubmit") {
        await firebase.login({ email, password })
        navigate("/")
      } else if (id === "registerSubmit") {
        await firebase.createUser({ email, password, userName })
        navigate("/")
      } else {
        console.log("Login/register did not work. Submitbutton: " + id)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div className={styles.image} />
      <form className={styles.form} onSubmit={submitHandler}>
        <label className={styles.greeting}>Welcome to Mema</label>
        <label className={styles.field1}>
          <Input
            type="text"
            name="email"
            className={styles.email}
            placeholder="email"
          />
        </label>
        <label className={styles.field2}>
          <Input
            type="password"
            name="password"
            className={styles.password}
            placeholder="password"
          />
        </label>
        <div className={styles.buttons}>
          <Button
            className={styles.button1}
            text="sign in"
            type="primary"
            htmlType="submit"
            id="loginSubmit"
          />
          <Button
            className={styles.button2}
            text="register"
            type="light"
            htmlType="submit"
            id="registerSubmit"
          />
        </div>
      </form>
    </>
  )
}
