import styles from "./Upload.module.scss"
import UploadIcon from "../../../assets/icons/upload.svg"
import { Button } from "../Button/Button"
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import axios from "axios"
import { firebase } from "../../../lib/firebase"
import AvatarPlaceholder from "../../../assets/avatar_placeholder.png"
import { ColorRing } from "react-loader-spinner"
import SiteNavigationBar from "../SiteNavigationBar/SiteNavigationBar"
import { UserContext } from "../../../context/UserContext"

export const Upload: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined,
  )
  const [imageName, setImageName] = useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showShareButton, setShowShareButton] = useState(false)
  const [isForSale, setIsForSale] = useState<boolean>(false)
  const [onboardingStatus, setOnboardingStatus] = useState<string>("")
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = await firebase.getToken()

        if (token != null && user != null) {
          axios.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${token}`
            return config
          })

          const response = await axios.get(
            `${apiUrl!}/auth/user/${user?.uid}`,
            {
              params: {
                code: "k7LlVZlTELTJw40_SrFwMdCvd_iD7NLiW0uKc7iIkWCPAzFuv5Dy9g==",
              },
            },
          )
          const userData = response.data
          setOnboardingStatus(userData.onboardingStatus)

          // Enable the checkbox if onboardingStatus is "complete"
          if (userData.onboardingStatus === "complete") {
            setIsCheckboxEnabled(true)
          } else {
            setIsCheckboxEnabled(false)
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error)
      }
    }

    fetchUserData()
  }, [apiUrl, user])

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(reader.result as string)
        setImageName(file.name)
      }
      reader.readAsDataURL(file)
    } else {
      setSelectedImage(undefined)
    }
  }

  // Used for having the button as an file input choice instead of the default.
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleUploadButtonClick = async () => {
    if (selectedImage) {
      try {
        setIsLoading(true)
        const price = isForSale ? 100 : 0

        const token = await firebase.getToken()
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }

        const imgFileData = new FormData()
        const fileNameWithDate = generateFilenameWithDate()
        console.log(fileNameWithDate)

        imgFileData.append(
          "File",
          await (await fetch(selectedImage)).blob(),
          fileNameWithDate,
        )

        const response = await axios.post(`${apiUrl}/upload`, imgFileData, {
          headers,
          params: {
            title: "tempTitle",
            price: price,
          },
        })
        console.log(response.data)

        setIsLoading(false)
        setShowModal(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isCheckboxEnabled) {
      setIsForSale(event.target.checked)
    }
  }

  const generateFilenameWithDate = () => {
    const now = new Date()
    const year = now.getFullYear().toString().slice(-2)
    const month = (now.getMonth() + 1).toString().padStart(2, "0")
    const day = now.getDate().toString().padStart(2, "0")
    const hours = now.getHours().toString().padStart(2, "0")
    const minutes = now.getMinutes().toString().padStart(2, "0")
    const seconds = now.getSeconds().toString().padStart(2, "0")
    const timestamp = `${year}${month}${day}-${hours}:${minutes}:${seconds}`
    return `${timestamp}_${imageName}`
  }

  return (
    <div className={styles.background}>
      <div className={styles.header}>
        <div className={styles.headerButtonContainer}>
          {selectedImage && (
            <Button
              text="back"
              className={styles.buttonBack}
              onClick={() => {
                if (showModal !== true) {
                  setSelectedImage(undefined)
                } else {
                  window.location.href = "/"
                }
              }}
            />
          )}
        </div>
        <SiteNavigationBar text="Upload meme" />
        <div className={styles.headerButtonContainer}>
          {selectedImage &&
            (!showShareButton ? (
              <Button
                text="next"
                className={styles.uploadButton}
                onClick={() => {
                  handleUploadButtonClick()
                  setIsLoading(true)
                  setShowShareButton(true)
                }}
              />
            ) : (
              <></>
            ))}
        </div>
      </div>
      <div className={styles.upload}>
        {!selectedImage && (
          <>
            <img
              src={UploadIcon}
              alt="uploadIcon"
              className={styles.uploadIcon}
            />
            <Button
              text="choose from pc"
              className={styles.uploadButton}
              onClick={handleButtonClick}
            />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </>
        )}
        {selectedImage && (
          <div>
            <img
              src={selectedImage}
              alt="Preview"
              className={styles.previewImage}
            />
            <label className="checkbox">
              For sale
              <input
                type="checkbox"
                checked={isForSale}
                onChange={handleCheckboxChange}
                disabled={!isCheckboxEnabled}
              />
              <p>
                To be able to have a meme for sale, you need to complete the
                onboarding via Stripe (found in your settings page).
              </p>
              {onboardingStatus !== "complete" && (
                <p>
                  Your onboarding status is: {onboardingStatus}. Please complete
                  onboarding to sell memes.
                </p>
              )}
            </label>
          </div>
        )}
        {showModal && (
          <div className={styles.modal}>
            <h3>Upload successful!</h3>
            <img
              className={styles.avatar}
              src={AvatarPlaceholder}
              alt="Avatar"
            />
            {user?.email}
          </div>
        )}
      </div>
      {isLoading && (
        <div className={styles.loader}>
          <ColorRing
            colors={["#FCFF71", "#DA72FF", "#66E4F5", "#DA72FF", "#FCFF71"]}
            height={60}
            width={70}
          />
        </div>
      )}
    </div>
  )
}
