import React, { useContext, useEffect, useState } from "react"
import styles from "./StripeOnboarding.module.scss"
import { firebase } from "../../../lib/firebase"
import { useStripeConnect } from "../../../hooks/useStripeConnect"
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js"
import axios from "axios"
import { UserContext } from "../../../context/UserContext"

export default function StripeOnboarding() {
  const [accountCreatePending, setAccountCreatePending] = useState(false)
  const [onboardingExited, setOnboardingExited] = useState(false)
  const [error, setError] = useState(false)
  const [connectedAccountId, setConnectedAccountId] = useState(null)
  const [status, setStatus] = useState(null)
  const stripeConnectInstance = useStripeConnect(connectedAccountId)
  const { user } = useContext(UserContext)
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = await firebase.getToken()

        if (token != null && user != null) {
          axios.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${token}`
            return config
          })

          const response = await axios.get(
            `${apiUrl!}/auth/user/${user?.uid}`,
            {
              params: {
                code: "k7LlVZlTELTJw40_SrFwMdCvd_iD7NLiW0uKc7iIkWCPAzFuv5Dy9g==",
              },
            },
          )
          const userData = response.data

          setStatus(userData.onboardingStatus)
          setConnectedAccountId(userData.stripeAccountId)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchUserData()
  }, [apiUrl, user])

  const handleSignUp = async () => {
    try {
      const token = await firebase.getToken()
      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      })

      setAccountCreatePending(true)
      setError(false)

      const response = await axios.post(`${apiUrl}/account`)
      const { account, error } = response.data

      if (error) {
        setError(true)
        return
      }

      setConnectedAccountId(account)
    } catch (err) {
      console.error(err)
      setError(true)
    } finally {
      setAccountCreatePending(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <h2>MeMA</h2>
      </div>
      <div className={styles.content}>
        {!connectedAccountId && <h2>Get ready to make money</h2>}
        {connectedAccountId && !stripeConnectInstance && (
          <h2>Add information to start accepting money</h2>
        )}
        {!connectedAccountId && <p>Stripe onboarding.</p>}
        {!accountCreatePending && !connectedAccountId && (
          <div>
            <button
              onClick={e => {
                e.preventDefault()
                !user ? (window.location.href = "/login") : handleSignUp()
              }}
            >
              Sign up via Stripe
            </button>
          </div>
        )}

        {status === "complete" && (
          <p>Verification complete, you can now sell your memes!</p>
        )}

        {status === "missing" && stripeConnectInstance && (
          <p>Missing information, please complete onboarding below.</p>
        )}
        {status === "pending" && stripeConnectInstance && (
          <p>Verification from Stripe is pending.</p>
        )}
        {(status === "missing" || status === "pending") &&
          stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding
                onExit={() => setOnboardingExited(true)}
              />
            </ConnectComponentsProvider>
          )}

        {error && <p className="error">Something went wrong!</p>}

        {(connectedAccountId || accountCreatePending || onboardingExited) && (
          <div className={styles.devCallout}>
            {connectedAccountId && (
              <p>
                Your Stripe connected account ID is:{" "}
                <code className="bold">{connectedAccountId}</code>
              </p>
            )}
            {accountCreatePending && <p>Creating a connected account...</p>}
            {onboardingExited && (
              <p>The Account Onboarding component has exited</p>
            )}
          </div>
        )}

        <div className="info-callout">
          <br />
          <p>
            This is an Alpha version for Connect onboarding using the Account
            Onboarding embedded component.{" "}
            <a
              href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=embedded"
              target="_blank"
              rel="noopener noreferrer"
            >
              View docs
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
