import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import "./styles/main.scss"
import { App } from "./app"

const container = document.getElementById("root") as Element
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)