import cn from "classnames"
import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon"

import styles from "./Menu.module.scss"
import { useContext } from "react"
import { UserContext } from "../../../context/UserContext"

export const Menu: React.FC = () => {
  const { user } = useContext(UserContext)
  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        <li
          className={styles.item}
          onClick={e => {
            e.preventDefault()
            window.location.href = "/"
          }}
        >
          <ButtonWithIcon icon="HouseBlack" />
        </li>
        <li
          className={styles.item}
          onClick={e => {
            e.preventDefault()
            window.location.href = "/trending"
          }}
        >
          <ButtonWithIcon icon="GrowBlack" />
        </li>
        <li className={styles.item} style={{ opacity: 0.3 }}>
          <ButtonWithIcon icon="SearchBlack" />
        </li>
      </ul>

      <div className={styles.divider} />

      <ul className={styles.list}>
        <li className={styles.item} style={{ opacity: 0.3 }}>
          <ButtonWithIcon icon="BookmarkBlack" />
        </li>
        <li className={styles.item} style={{ opacity: 0.3 }}>
          <ButtonWithIcon icon="BellBlack" />
        </li>
        <li
          className={styles.item}
          onClick={e => {
            e.preventDefault()
            !user
              ? (window.location.href = "/login")
              : (window.location.href = "/profile")
          }}
        >
          <ButtonWithIcon icon="ProfileEditBlack" />
        </li>
        <li
          className={styles.item}
          onClick={e => {
            e.preventDefault()
            window.location.href = "/settings"
          }}
        >
          <ButtonWithIcon icon="GearBlack" />
        </li>
        <li className={styles.item}>
          <ButtonWithIcon icon="MarketPlace" />
        </li>
        <li
          className={cn([styles.item, styles.item_upload])}
          onClick={e => {
            e.preventDefault()
            !user
              ? (window.location.href = "/login")
              : (window.location.href = "/upload")
          }}
        >
          <ButtonWithIcon type="primary" icon="Upload" />
        </li>
      </ul>
    </div>
  )
}
