import { Meme } from "../../../types/meme"
import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon"
import styles from "./MemeStats.module.scss"

export const MemeStats: React.FC<{
  meme: Meme
}> = ({ meme }) => {
  return (
    <div className={styles.container}>
      <div className={styles.statsContainer}>
        <h2>Meme stats</h2>
        <p></p>
        {/* <div className={styles.textContainer}>
          <p>Views</p>
        </div>
        <p>10 000</p>
        <div className={styles.textContainer}>
          <p>Interactions</p>
        </div>
        <p>6000</p> */}
        <div className={styles.textContainer}>
          <p>Likes</p>
        </div>
        <p>{meme.nrOfLikes}</p>
        <div className={styles.textContainer}>
          <p>Dislikes</p>
        </div>
        <p>{meme.nrOfDislikes}</p>
        {/* <div className={styles.textContainer}>
          <p>Comments</p>
        </div>
        <p>500</p> */}
      </div>
      <div className={styles.buyIcon} style={{ opacity: 0.4 }}>
        <ButtonWithIcon type="primary" icon="Buy" />
      </div>
    </div>
  )
}
