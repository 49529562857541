import axios from "axios"
import React, { useState, useEffect } from "react"
import { Navigate } from "react-router-dom"

const CheckoutReturn = () => {
  const [status, setStatus] = useState(null)
  const [customerEmail, setCustomerEmail] = useState("")

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get("session_id")
    const apiUrl = process.env.REACT_APP_API_URL

    const fetchSessionStatus = async () => {
      try {
        const response = await axios.get(`${apiUrl}/checkoutsession/status`, {
          params: {
            session_id: sessionId,
            code: "_rhNNhgWEtbszEbN9BUVDU_3WNL0JSTg6l0t_FNyeIhKAzFupjJgvQ==",
          },
        })

        const data = response.data

        setStatus(data.status)
        setCustomerEmail(data.customer_email)
      } catch (error) {
        console.error("Error fetching session status:", error)
      }
    }

    fetchSessionStatus()
  }, [])

  if (status === "open") {
    return <Navigate to="/checkout" />
  }

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}. If you have any questions, please email{" "}
          <a href="mailto:futharkab@gmail.com">futharkab@gmail.com</a>.
        </p>
      </section>
    )
  }

  return null
}

export default CheckoutReturn
