import styles from "./SiteNavigationBar.module.scss"
import React from "react"

const SiteNavigationBar: React.FC<{
  text: string
}> = ({ text }) => {
  return (
    <div className={styles.topContainer}>
      <div className={styles.topBar}>
        <p className={styles.topBarText}>{text}</p>
      </div>
    </div>
  )
}

export default SiteNavigationBar
