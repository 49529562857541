import { PropsWithChildren } from "react"
import { Menu } from "../../common/Menu/Menu"
import styles from "./BaseLayout.module.scss"

export const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.menuSection}>
        <img
          className={styles.logo}
          src="../../../../mema-logo-DARK.png"
          alt="Logo"
          onClick={e => {
            e.preventDefault()
            window.location.href = "/"
          }}
        />
        <Menu />
      </div>
      <div className={styles.pageContent}>{children}</div>
    </div>
  )
}
