import * as firebaseApp from "firebase/app"
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
} from "firebase/auth"
import { getFirestore, doc, getDoc } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics"
import axios from "axios"

class Firebase {
  private readonly app
  private readonly firestore
  private apiUrl = process.env.REACT_APP_API_URL

  constructor() {
    this.app = firebaseApp.initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    })
    this.firestore = getFirestore(this.app)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const analytics = getAnalytics(this.app)
  }

  createUser = async (params: {
    email: string
    password: string
    userName: string
  }) => {
    const auth = getAuth()
    const { email, password, userName } = params

    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )
      const token = await this.getToken()
      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      })

      const response = await axios.post(`${this.apiUrl}/auth/user`, {
        uid: user.uid,
        userName: userName,
        email: email,
        params: {
          code: "dsgKKaOSm00k2heqOQK4EP10hQzzMYg0I5E0xVJ80fqdAzFuEwWZmQ==",
        },
      })
      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`)
      }

      await sendEmailVerification(user)
      return user
    } catch (err) {
      throw err
    }
  }

  login = async (params: { email: string; password: string }) => {
    const auth = getAuth()
    const { email, password } = params

    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password)
      return user
    } catch (err) {
      throw err
    }
  }

  logout = async () => {
    const auth = getAuth()
    try {
      await signOut(auth)
      return
    } catch (err) {
      throw err
    }
  }

  onAuthStateChange(cb: (user: any) => void) {
    const auth = getAuth()
    onAuthStateChanged(auth, cb)
  }

  getToken = async (retries = 3) => {
    const auth = getAuth()

    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const idToken = await auth.currentUser?.getIdToken(true)

        if (idToken) {
          return idToken
        } else {
          console.log(`Failed to retrieve token on attempt ${attempt}`)
        }
      } catch (error) {
        console.error(`Error retrieving token on attempt ${attempt}:`, error)
      }

      // Wait for a short period before retrying
      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    return null
  }

  getStripeAccountId = async () => {
    const auth = getAuth()
    const user = auth.currentUser
    if (!user) {
      throw new Error("User not authenticated")
    }

    const userDocRef = doc(this.firestore, "users", user.uid)
    const userDoc = await getDoc(userDocRef)

    if (userDoc.exists()) {
      return userDoc.data().stripeAccountId
    } else {
      throw new Error("No such document!")
    }
  }
}

export const firebase = new Firebase()
