import { LoginForm } from "../../components/forms/Login/Login"
import { useTitle } from "../../hooks/useTitle"
import styles from "./Login.module.scss"

export const LoginPage: React.FC = () => {
  useTitle("Login")
  return (
    // <BaseLayout>
    //   <LoginForm />
    // </BaseLayout>
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {/* <p className={styles.greetMessage}>
          Enter your MeMA account
        </p> */}
        <LoginForm />
      </div>
    </div>
  )
}
