import { InputHTMLAttributes } from 'react'
import styles from "./Input.module.scss"

export const Input: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  placeholder,
  name,
  type
}) => {
  return (
    <input
      className={styles.input}
      placeholder={placeholder}
      name={name}
      type={type}
    />
  )
}